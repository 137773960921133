// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
	apiKey: 'AIzaSyDxdUCMrCrYeDah9f5gE6hn5z4v3eXkics',
	authDomain: 'mindfulcare-eed3c.firebaseapp.com',
	databaseURL: 'https://mindfulcare-eed3c-default-rtdb.firebaseio.com',
	projectId: 'mindfulcare-eed3c',
	storageBucket: 'mindfulcare-eed3c.appspot.com',
	messagingSenderId: '88012071660',
	appId: '1:88012071660:web:dfdeddcc3269c16b4b7bc9',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getDatabase(app)
