import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Grid, Container, Button } from "@material-ui/core";
import ValidateUser from "../../components/ValidateUser";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import {  top, getCollectionsComplete, } from "../../resources/js/functions";
import cuadro from '../../resources/images/podcast/cuadro.svg'
import IconoDudas from '../../resources/images/streamingOnLine/icono-dudas.svg'
import "./styles.scss";

export default function Podcasts() {
  const params = useParams();

  const [dataInfo, setDataInfo] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    top();
    getVideo();
  }, []);

  const getVideo = async () => {
    const result = await getCollectionsComplete(`podcast/${params.id}`);
    setDataInfo(result);
  };

  return (
    <>
      <ValidateUser>
        <Header />
        <div id="podcasts">
          <div className="section-one">
            <Container maxWidth="lg">
              <Grid container direction="row" alignItems="stretch" justifyContent="center" spacing={3}>
                <Grid item lg={12} md={12} xs={12}>
                  <div onClick={() => navigate(-1)} className="cursor">{'< Regresar'}</div>
                </Grid>
                <Grid item lg={12} md={12} xs={12}>
                  <div className="titulos" style={{ marginBottom: '40px' }}>{dataInfo.titulo}</div>
                </Grid>
              
              </Grid>
            </Container>
          </div>
          <div className="abner">
            <div className="cuadro" style={{backgroundImage: `url(${cuadro})`}} />
            <Container maxWidth="lg" style={{ zIndex: "9", position: "inherit" }}>
              <Grid container direction="row" alignItems="stretch" justifyContent="center" spacing={3}>
                <Grid item lg={7} md={10} xs={12}>
                  <div className="contenedor-streaming">
                    <iframe title="streaming" src={dataInfo.url} allow="autoplay; fullscreen" allowFullScreen className="estilo-streaming" style={{ border: `10px solid` }} />
                  </div>
                </Grid>
              </Grid>
            </Container>
            <div id="section-two" className="wow fadeInUp" data-wow-delay='.2s' data-wow-offset='0' data-wow-duration='1s'>
              <Container maxWidth="lg">
                <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2}>
                  <Grid item xs={12} sm={12} md={5} lg={5}>
                    <div className="full center">
                      <img src={IconoDudas} alt="icono" className="icono-descargable" />
                      <p>¿Tienes dudas?</p>
                      <a href="mailto:info@mindfulcaro.com" style={{ margin: 'auto', textDecoration: 'none', color: '#fff' }}>
                        <div className="btn btn-one center" style={{ margin: 'auto' }}>
                          Escríbenos
                        </div>
                      </a>
                    </div>
                  </Grid>
                </Grid>
              </Container>
            </div>
          </div>
          <Footer />
        </div>
      </ValidateUser>
    </>
  );
}
