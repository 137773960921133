import { BrowserRouter, Route, Routes } from "react-router-dom";
// Vistas
import Login from "../views/Login";
import Home from "../views/Home";
import Pilares from "../views/Pilares";
import Speakers from "../views/Speakers";
import StreamingOnLine from "../views/StreamingOnLine";
import Podcasts from "../views/Podcasts";
import PilresView from "../views/PilresView";
import Adminstration from "../views/Adminstration";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Login />} />
        {/* <Route exact path="/login-pruebas" element={<Login />} /> */}
        <Route exact path="/home" element={<Home />} />
        <Route exact path="/pilares" element={<PilresView />} />
        <Route exact path="/pilar/:pilar" element={<Pilares />} />
        <Route exact path="/quienes-somos" element={<Speakers />} />
        <Route exact path="/streaming/:pilar/:id" element={<StreamingOnLine />} />
        <Route exact path="/podcast/:id" element={<Podcasts />} />
        {/* <Route exact path="/conocenos" element={<AboutUs />} />
        <Route exact path="/panel-admin-connect" element={<Adminstration />} /> 
        <Route exact path="/panel-pruebas-connect" element={<Pruebas />} /> 
        <Route exact path="/panel-chat" element={<PanelChat />} />  */}
        {/* 
        */}

        {/* 
        <Route exact path="/libro-de-visitas" element={<Guestbook />} />
        <Route exact path="/comentarios" element={<Comments />} />
        <Route exact path="/reportes-connect" element={<Reporte />} />
       */}
      </Routes>
    </BrowserRouter>
  );
}
