import React, { useState, useEffect } from "react";
import { getAuth, signOut } from "firebase/auth";
import { db } from "../../config/firebase";
import { ref, onChildChanged } from "firebase/database";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { useNavigate } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Drawer, Button } from "@material-ui/core";
import { Hidden } from "@material-ui/core";
import { getCollections, downloadFile } from "../../resources/js/functions";
import Datos from "./opciones";
import PDF from "../../resources/descargables/agenda.pdf";
import Logo from "../../resources/images/header/logo.svg";
import "../Header/styles.scss";
import "./styles.scss";

const styles = {
  fondoMenu: {
    background: `#F8F7F7 0% 0% no-repeat padding-box`,
    maxWidth: 540,
    height: `100vh`,
    width: `210px`,
    overflow: "auto",
    padding: "15px 15px 0 15px",
    color: "#263238",
    zIndex: 2,
  },
  lista: {
    display: "grid",
  },
  button: {
    color: "#51647D",
    width: 200,
    lineHeight: 1.1,
    fontSize: 15,
    fontWeight: "bold",
    textTransform: "none",
    textDecoration: "none !important",
  },
  p: {
    margin: "5px 0",
    color: "#54565B",
    fontSize: "22px",
    fontWeight: "bold",
  },
  h3: {
    color: "#51647D",
    fontSize: "28px",
    fontWeight: "bold",
    textTransform: "uppercase",
    marginLeft: "20px",
  },
};

function Menu(props) {
  const [openMenu, setOpenMenu] = useState(false);
  const [descargarCertificado, setDescargarCertificado] = useState(false);
  // const [urlprops.location.pathname, setUrlprops.location.pathname] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    // const dbRef = ref(db, "configuracion/");
    // onChildChanged(dbRef, (data) => {
    //   ActivarCertificado();
    // });
    // console.log(window.location.pathname);
    // setUrlprops.location.pathname(location.props.location.pathnamename);
    // ActivarCertificado();
    // validarCertificado();
  }, []);

  // const ActivarCertificado = async () => {
  //   const data = await getCollections("configuracion");
  //   const values = Object.values(data);
  //   setDescargarCertificado(values[1].certificado);
  // };

  // const validarCertificado = async () => {
  // 	const ref = firebase.database().ref('configuraciones');
  // 	const snapshot = await ref.once('value');
  // 	const resultado = await snapshot.val();
  // 	setDescargarCertificado(resultado.activarCertificado);
  // };

  const logout = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        window.localStorage.clear();
        navigate("/");
      })
      .catch((error) => {
        console.log("error: " + error);
        // An error happened.
      });
  };

  const toggleDrawer = () => {
    setOpenMenu(!openMenu);
  };

  const botonMenu = (to, titulo, callback = null, index) => {
    const { classes } = props;
    return (
      <>
        <Button id="btn-mobile" onClick={() => navigate(to)} key={index} className={classes.button}>
          <div className="btn-inline">
            <div className="float-left titulo-menu full">
              <span>{titulo}</span>
            </div>
          </div>
        </Button>
      </>
    );
  };

  const sideList = (className, back) => (
    <div className={className} id="menu-mobile">
      <div style={{ fontSize: "30px", fontWeight: 400, marginBottom: "30px", color: '#51647D' }}>Índice</div>
      {Object.values(Datos).map((val, index) => {
        if (val.texto !== "Cerrar sesión") {
          
          return botonMenu(val.link, val.texto, index);
        }
      })}
      <Button id="btn-mobile" onClick={() => downloadFile(PDF, 'Agenda')} className={classes.button}>
        <div className="btn-inline">
          <div className="float-left titulo-menu full">
            <span>Descargar la agenda</span>
          </div>
        </div>
      </Button>
      <Button id="btn-mobile" onClick={logout} className={classes.button}>
        <div className="btn-inline">
          <div className="float-left titulo-menu full">
            <span>Cerrar sesión</span>
          </div>
        </div>
      </Button>
    </div>
  );

  const { classes, back } = props;
  return (
    <>
      <Grid container justifyContent="center" alignItems="center" direction="row" spacing={3}>
        <Grid item lg={3} md={6} sm={10} xs={10}>
          <img id="imgBanner" src={Logo} alt="logo" onClick={() => navigate("/home")} className="cursor logo" />
        </Grid>
        <Grid item lg={9} md={6} sm={2} xs={2} className="ass">
          <Hidden only={["lg", "xl"]}>
            <div className="hijos" style={{ float: "right", color: "#51647D" }} onClick={toggleDrawer}>
              <i className="fas fa-bars"></i>
            </div>
          </Hidden>
          <Hidden only={["xs", "sm", "md"]}>
            <ul className="nav-links" id="menuHeader">
              <li className={`hijos`}>
                <div onClick={() => navigate("/home")} className={`padding-li ${window.location.pathname === "/home" ? "border" : ""}`}>
                  Home
                </div>
              </li>
              <li className={`hijos`}>
                <div onClick={() => navigate("/pilares")} className={`padding-li ${window.location.pathname === "/pilares" ? "border" : ""}`}>
                  Pilares
                </div>
              </li>
              <li className={`hijos`}>
                <div className={`padding-li`} onClick={() => downloadFile(PDF, 'Agenda')}>
                  Descarga la agenda
                </div>
              </li>
              <li className={`hijos`}>
                <div onClick={() => navigate("/quienes-somos")} className={`padding-li ${window.location.pathname === "/quienes-somos" ? "border" : ""}`}>
                  ¿Quiénes somos?
                </div>
              </li>
              {/*
               <li className={`hijos`}>
                <div className={`padding-li`} onClick={() => downloadFile(PDF, 'Certificado Mindcare')}>
                  Descargar certificado
                </div>
              </li> */}
             
              {/* {descargarCertificado && (
                <li className={`hijos`}>
                  <div onClick={() => navigate("/certificado")} className={`padding-li ${window.location.pathname === "/certificado" ? "border" : ""}`}>
                    Descargar certificado
                  </div>
                </li>
              )} */}
              <li className={`hijos`}>
                <div onClick={logout} className={`padding-li`}>
                  Cerrar sesión
                </div>
              </li>
            </ul>
          </Hidden>
        </Grid>
      </Grid>
      <Drawer anchor="right" open={openMenu} onClose={toggleDrawer}>
        <div className={classes.fondoMenu}>
          {sideList(classes.lista, back)}
          </div>
      </Drawer>
    </>
  );
}

Menu.propTypes = {
  classes: PropTypes.shape({}).isRequired,
};

export default withStyles(styles)(Menu);
