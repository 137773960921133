import React from 'react';
import { Grid } from "@material-ui/core";
import logo from "../../../resources/images/login/logo.svg";

export default function SectionWelcome(props) {
  return (
    <Grid item xs={12} sm={6} md={5} lg={5} className="wow fadeInLeft" data-wow-delay='.2s' data-wow-offset='0' data-wow-duration='.5s'>
      <div className="fullHeigth container-logo">
        <div className="overlap">
          <div className="full center ">
            <img src={logo} alt="logo" />
            <div className="welcome-text full center ">
              <h1>¡Bienvenido!</h1>
              <div className="full center">
                <p>Este es tu espacio de autoconocimiento, autocuidado y bienestar continuo con las mejores herramientas y prácticas avaladas científicamente.</p>
                <p>¡Acompáñanos a crear un mundo más consciente, saludable y sustentable!</p>
                {/* <p><Button className="cursor btn-naranja blanco" onClick={() => activeModal(true)}>Ver agenda</Button></p>
                <p>FALTAN:</p>
                <p>{`${fecha.dia} días, ${fecha.hora} horas, ${fecha.minutos} minutos, ${fecha.segundos} segundos`}</p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Grid>
  );
}
