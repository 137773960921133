import React, { useEffect } from "react";
import { useParams } from 'react-router-dom';
import ValidateUser from '../../components/ValidateUser';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { top } from '../../resources/js/functions';
import Emocional from './Emocional';
import Fisico from './Fisico';
import Nutricion from './Nutricion';

export default function Pilares() {
  let { pilar } = useParams();
  useEffect(() => {
    top();
  }, [])

  return (
    <>
      <ValidateUser>
        <Header />
        <div id="pilares">
          {pilar === 'emocional-mental' && <Emocional />}
          {pilar === 'fisico' && <Fisico />}
          {pilar === 'nutricion' && <Nutricion />}
        </div>
        <Footer />
      </ValidateUser>
    </>
  );
}
