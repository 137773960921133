const array = [
	{
		texto: 'Home',
		link: '/home',
	},
	{
		texto: 'Pilares',
		link: '/pilares',
	},
	// {
	// 	texto: 'Conócenos',
	// 	link: '/conocenos',
	// },
	{
		texto: 'Quiénes somos',
		link: '/quienes-somos',
	},
	{
		texto: 'Cerrar sesión',
		link: '',
	},
];

export default array;
