import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Grid, Container } from "@material-ui/core";
import { top } from '../../resources/js/functions';
import Header from "../../components/Header";
import ValidateUser from "../../components/ValidateUser";
import Footer from "../../components/Footer";
import pilarMental from '../../resources/images/home/pilar-emocional.svg';
import pilarFisico from '../../resources/images/home/pilar-fisico.svg';
import pilarNutricion from '../../resources/images/home/pilar-nutricion.svg';
import "react-alice-carousel/lib/alice-carousel.css";
import "./styles.scss";

export default function Pilares() {
  const navigate = useNavigate();
  useEffect(() => {
    top();
  }, [])

  return (
    <>
      <ValidateUser>
        <Header />
        <div id="home">
          <Container maxWidth="lg" className=" wow fadeIn mb30" data-wow-delay='.2s' data-wow-offset='0' data-wow-duration='.5s'>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <h1>Nuestros pilares</h1>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <img src={pilarMental} alt="pilar" className="full cursor" onClick={() => navigate('/pilar/emocional-mental')} />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <img src={pilarFisico} alt="pilar" className="full cursor" onClick={() => navigate('/pilar/fisico')} />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <img src={pilarNutricion} alt="pilar" className="full cursor" onClick={() => navigate('/pilar/nutricion')} />
              </Grid>
            </Grid>
          </Container>
        </div>
        <Footer />
      </ValidateUser>
    </>
  );
}
