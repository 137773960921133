import React, { useState, useEffect } from "react";
import { Grid, Container } from "@material-ui/core";
import ValidateUser from "../../components/ValidateUser";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { isDesktop } from 'react-device-detect';
import { getCollections, top } from "../../resources/js/functions";
import logoImta from "../../resources/images/speakers/Logo-IMTA-1.svg";
import logo1 from "../../resources/images/speakers/logo1.svg";
import logo2 from "../../resources/images/speakers/logo2.svg";
import logo3 from "../../resources/images/speakers/logo3.svg";
import logo4 from "../../resources/images/speakers/logo4.svg";
import logo5 from "../../resources/images/speakers/logo5.svg";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import atras from '../../resources/images/home/atras.svg';
import siguiente from '../../resources/images/home/siguiente.svg';
import pilarEmocional from "../../resources/images/speakers/pilar-emocional.svg";
import pilarFisico from "../../resources/images/speakers/pilar-fisico.svg";
import pilarNutricional from "../../resources/images/speakers/pilar-nutricional.svg";
import "./styles.scss";
import Carousel from "./Carousel";


export default function Speakers() {
  const [speakers, setSpeakers] = useState([]);
  const [logos, setLogos] = useState([logoImta,logo1, logo2, logo3, logo4,logo5]);

  useEffect(() => {
    top();
    getSpeakers();
  }, [])

  const getSpeakers = async () => {
    const data = await getCollections('speakers');
    setSpeakers(data);
  }

  const color = nombre => {
    const color = ''

    return color;
  }

  const renderNextButton = ({ isDisabled }) => {
    return <img src={siguiente} alt="img" style={{ position: "absolute", right: '-6%', top: '30%', cursor: 'pointer', color: "#E9010B" }} />
  };

  const renderPrevButton = ({ isDisabled }) => {
    return <img src={atras} alt="img" style={{ position: "absolute", left: '-6%', top: '30%', cursor: 'pointer', color: "#E9010B" }} />
  };


  return (
    <ValidateUser>
      <Header />
      <div id="speakers" className="wow fadeInUp" data-wow-delay='.2s' data-wow-offset='0' data-wow-duration='.5s'>
        <Container maxWidth="lg" className=" wow fadeIn" data-wow-delay='.2s' data-wow-offset='0' data-wow-duration='.5s'>
          <Grid container direction="row" alignItems="center" justifyContent="center" spacing={5}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <h1>¿Quiénes somos?</h1>
              <p>Somos una empresa 100% dedicada en crear, fomentar y mantener ENTORNOS SALUDABLES, PRODUCTIVOS Y EFECTIVOS, previniendo los factores de riesgo psicosociales en diferentes organizaciones, centros de trabajo y centros de estudio.</p>
            </Grid>
          </Grid>
        </Container>
        <Container maxWidth="lg">
          <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <h1>¿Cómo lo hacemos?</h1>
              <p>Brindamos soluciones integrales enfocadas en el cuidado y entrenamiento del CUERPO, la MENTE y las EMOCIONES, a través de programas y contenidos avalados científicamente.</p>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <h1>¿Cuál es nuestra trayectoria?</h1>
              <p>MindfulCare cuenta con la certificación internacional del IMTA y diversas certificaciones en meditación y mindfulness para el trabajo: Certificación del Instituto Mexicano de Mindfulness, MBSR para reducción de estrés por la Universidad de Massachusetts, Certificación Yoga Alliance, Certificación de International Coaching Federation, entre otras. Diferentes medios de comunicación han reconocido nuestro trabajo; y en el año 2023 fuimos finalistas como la mejor empresa de Mindfulness en México por el festival BienFest.</p>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <AliceCarousel
                mouseTrackingEnabled
                items={logos}
                autoPlayInterval="2000"
                // autoPlay={true}
                infinite={true}
                mouseTracking
                // startIndex={0}
                // slideToIndex={0}
                disableButtonsControls={isDesktop ? false : true}
                renderPrevButton={renderPrevButton}
                renderNextButton={renderNextButton}
                responsive={{
                  425: {
                    items: 2,
                  },
                  640: {
                    items: 2,
                  },
                  1024: {
                    items: 3,
                  },
                }}
              >
                {logos.map((val, index) => {

                  return (
                    <div className="center">
                      <div style={{ backgroundImage: `url(${val})`, filter: "grayscale(1)" }} className="logos" />

                    </div>

                  );
                })}
              </AliceCarousel>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <h1>Conoce a nuestro equipo</h1>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <img src={pilarEmocional} alt="pilar" className="mb30 mt30 img-pilar" />
            </Grid> 
            {Object.values(speakers).map((val) => {
              if (val.nombre === 'Jaume Casas' || val.nombre === 'Ana Godina' || val.nombre === 'Coral de la Vega') {
                return <>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <div className="contenedor-speaker" key={val.nombre}>
                      <img src={val.urlFotoCuadrada} alt="speaker" />
                      <div className="nombre" style={{ color: '#7EA3C9' }} >{val.nombre}</div>
                      <div className="separador" />
                      <div className="cv">
                        <div>{val.puesto}</div>
                        <div>{val.empresa}</div>
                      </div>
                    </div>
                  </Grid>
                </>
              }
            })}
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <img src={pilarFisico} alt="pilar" className="mb30 mt30 img-pilar" />
            </Grid>
            {Object.values(speakers).map((val) => {
              if (val.nombre === 'Alex Shuz' || val.nombre === 'Ceci Lastra' || val.nombre === 'Ana Karen') {
                return <>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <div className="contenedor-speaker" key={val.nombre}>
                      <img src={val.urlFotoCuadrada} alt="speaker" />
                      <div className="nombre" style={{ color: '#E5805F' }} >{val.nombre}</div>
                      <div className="separador" />
                      <div className="cv">
                        <div>{val.puesto}</div>
                        <div>{val.empresa}</div>
                      </div>
                    </div>
                  </Grid>
                </>
              }
            })}
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <img src={pilarNutricional} alt="pilar" className="mb30 mt30 img-pilar" />
            </Grid>
            {Object.values(speakers).map((val) => {
              if (val.nombre === 'Marian Aguilar' || val.nombre === 'More Hurtado') {
                return <>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <div className="contenedor-speaker" key={val.nombre}>
                      <img src={val.urlFotoCuadrada} alt="speaker" />
                      <div className="nombre" style={{ color: '#328473' }} >{val.nombre}</div>
                      <div className="separador" />
                      <div className="cv">
                        <div>{val.puesto}</div>
                        <div>{val.empresa}</div>
                      </div>
                    </div>
                  </Grid>
                </>
              }
            })}
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <h1>Nuestros clientes</h1>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Carousel />
            </Grid>
          </Grid>
        </Container>
      </div>
      <Footer />
    </ValidateUser >
  );
}
