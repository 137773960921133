import React from "react";
import { Grid, Container } from "@material-ui/core";
import parse from "html-react-parser";

export default function SpeakersConfertencias({ speakersActive }) {
  // const speakersActive = props.speakersActive;
  // console.log(speakersActive);
  return (
    <>
      <div style={{ marginTop: '40px' }}>
        <Container maxWidth="lg" style={{ zIndex: "999999", position: "inherit" }}>
          <Grid container direction="row" alignItems="stretch" justifyContent="center" spacing={3}>
            <Grid item lg={12} md={12} xs={12}>
              <div className="titulos">Acerca del experto</div>
            </Grid>
            <Grid item lg={12} md={12} xs={12}>
              <div className="full">
                <div className="name-conferencista full ">
                  {/* <div style={{backgroundImage: `url(${info.urlFoto})`}}> */}
                  <div >
                    <img className="img-conferencista" style={{ borderRadius: '50%' }} src={speakersActive.urlFoto} alt="img" />
                  </div>
                  <span className="tendencias bold" style={{ display: "inline-block" }}>
                    <span className="job-conferencista">
                      <span className="name">{`${speakersActive.nombre}`}</span> <br />
                      <span className="job">
                        {speakersActive.puesto} - {speakersActive.empresa}
                      </span>
                      <br />
                      <br />
                      <span className="description-conferencista">{parse(`${speakersActive.descripcion}`)}</span>
                    </span>
                  </span>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>

    </>
  );
}
