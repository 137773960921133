import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Container } from "@material-ui/core";
import { isDesktop } from 'react-device-detect';
import AliceCarousel from "react-alice-carousel";
import { getCollections, getCollectionsComplete } from "../../../resources/js/functions";
import iconoFisico from '../../../resources/images/pilares/icono-pilar-fisico.svg';
import atras from '../../../resources/images/home/atras.svg';
import siguiente from '../../../resources/images/home/siguiente.svg';
import banner from '../../../resources/images/home/banner-fisico.svg';
import "react-alice-carousel/lib/alice-carousel.css";
import "./styles.scss";


export default function PilarFisico() {
  const [conferencias, setConferencias] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getConferencias();
  }, [])

  const getConferencias = async () => {
    let array = [];
    const data = await getCollections('conferencias');
    const conferenciaPilar = data.filter(val => val.pilar === 'Fisico');
    const spekearsFirebase = await getCollectionsComplete('speakers');
    Object.values(conferenciaPilar).map(val => {
      const find = spekearsFirebase[val.speaker]
      array.push({ ...val, speaker: find })
    });
    setConferencias(array);
  }

  const renderNextButton = ({ isDisabled }) => {
    return <img src={siguiente} alt="img" style={{ position: "absolute", right: '-6%', top: '30%', cursor: 'pointer', color: "#E9010B" }} />
  };

  const renderPrevButton = ({ isDisabled }) => {
    return <img src={atras} alt="img" style={{ position: "absolute", left: '-6%', top: '30%', cursor: 'pointer', color: "#E9010B" }} />
  };
  return (
    <>
      <div className="pilarFisico">
        <img src={banner} alt="asas" className="full banner" />
        <Container maxWidth="lg" className=" wow fadeIn" data-wow-delay='.2s' data-wow-offset='0' data-wow-duration='.5s'>
          <Grid container direction="row" alignItems="center" justifyContent="center" spacing={5}>
            <Grid item xs={12} sm={12} md={5} lg={5}>
            </Grid>
            <Grid item xs={12} sm={12} md={7} lg={7}>
              {!isDesktop && <div className="center text-mobile">
                <img src={iconoFisico} alt="as" className="icono-pilar" />
                <h1>Pilar Físico</h1>
                <p>¡Es la hora de mover el cuerpo!</p>
                <p>La actividad física y la conciencia corporal son pilares fundamentales de la prevención de enfermedades, sobrepeso y padecimientos causados por el sedentarismo.</p>
                <p>¡Tu cuerpo está diseñado para moverse y el movimiento genera motivación! Atrévete a encontrar esa actividad que te motiva y sal de tu zona de confort.</p>
              </div>}
            </Grid>
          </Grid>
        </Container>
      </div>
      <Container maxWidth="lg" className=" wow fadeIn mt30" data-wow-delay='.2s' data-wow-offset='0' data-wow-duration='.5s'>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <h1>Videos</h1>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <AliceCarousel
              mouseTrackingEnabled
              items={conferencias}
              autoPlayInterval="2000"
              autoPlay={true}
              infinite={true}
              mouseTracking
              disableButtonsControls={!isDesktop}
              renderPrevButton={renderPrevButton}
              renderNextButton={renderNextButton}
              responsive={{
                425: {
                  items: 2,
                },
                640: {
                  items: 2,
                },
                1024: {
                  items: 3,
                },
              }}
            >
              {conferencias.map((val, index) => {
                return (
                  <div className="center" style={{ marginLeft: '15px' }} key={index} >
                    <img src={val.portada} alt="portada" className="cursor" style={{ width: '100%' }} onClick={() => val.url !== ' ' ? navigate(`/streaming/fisico/${val.id}`) : null} />
                    <div className="nombre-speaker-fisico">Con {val.speaker.nombre}</div>
                  </div>
                );
              })}
            </AliceCarousel>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <p className="center" style={{ color: '#51647D' }}>Accede a los diferentes videos con herramientas de bienestar y autocuidado que estarán disponibles 24/7.</p>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
