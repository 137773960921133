import React from "react";
import { getAuth, signOut } from "firebase/auth";
import { Grid, Container, Button } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import PDF from "../../resources/descargables/certificado.pdf";
import { downloadFile } from "../../resources/js/functions";
import Connect from "../../resources/images/footer/spira-connect.svg";
import linkedin from "../../resources/images/footer/linkedin.png";
import facebook from "../../resources/images/footer/facebook.png";
import instagram from "../../resources/images/footer/instagram.png";
import "./styles.scss";

const date = new Date().getFullYear();

export default function Footer() {
  const navigate = useNavigate();

  const logout = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        window.localStorage.clear();
        navigate("/");
      })
      .catch((error) => {
        console.log("error: " + error);
        // An error happened.
      });
  };

  return (
    <>
      <div id="footer">
        <Container maxWidth="lg">
          <Grid container direction="row" alignItems="center" justifyContent="center">
            <Grid item xs={12} sm={2} md={6} lg={4}>
              <div className="logo-connect">
                <img src={Connect} alt="img" className="img-connect" />
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={4} className="center">
              <Grid container direction="row" alignItems="center" justifyContent="center">
                {/* 
                */}
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <div className="titulo-menu bold">Spira Connect</div>
                </Grid>
                <ul >
                  <li><div className="option" onClick={() => navigate("/home")}>Home</div></li>
                  <li><div className="option" onClick={() => navigate("/pilares")}>Pilares</div></li>
                  <li><div className="option" onClick={() => navigate("/quienes-somos")}>¿Quiénes somos?​</div></li>
                  <li><div className="option" onClick={() => downloadFile(PDF, 'Agenda')}>Descargar la agenda</div></li>
                </ul>


                {/* <ul>
                  <li><div className="option" onClick={() => navigate("/conferencistas")}>Conferencistas</div></li>
                </ul> */}

                {/* 
                */}
                {/* <Grid item xs={12} sm={6} md={6} lg={6}>
                  <div onClick={() => navigate("/home")}>Home</div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <div className="option" onClick={() => navigate("/conocenos")}>Conócenos</div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <div className="option" onClick={() => navigate("/conferencistas")}>Conferencistas</div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <div className="option" onClick={() => downloadFile(PDF, 'Agenda')}>Descargar Agenda</div>
                </Grid> */}
              </Grid>
            </Grid>
            {/* </Grid> */}
            <Grid item xs={12} sm={2} md={3} lg={4}>
              <div className="out-sesion" >
                <Button type="submit" className="btn btn-one" onClick={logout}>
                  Cerrar sesión
                </Button>
              </div>
            </Grid>
          </Grid>
        </Container>
        <div className="divider" />
        <Container maxWidth="lg">
          <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2}>
            <Grid item xs={12} sm={8} md={9} lg={9}>
              <div className="all-rights-reserved">&copy; {`Derechos reservados, Spira ${date}`}</div>
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={3}>
              <div className="social-media" >
                <div className="follow-us padres" style={{ display: "inline-block" }}>
                  <span className="hijoss">Siguenos en</span>
                  <img src={linkedin} alt="img" className="hijos cursor" onClick={() => window.open("https://www.linkedin.com/in/carocanepa", "_blank")} />
                  {/* <img src={facebook} alt="img" className="hijos cursor" onClick={() => window.open("", "_blank")} /> */}
                  <img src={instagram} alt="img" className="hijos cursor" onClick={() => window.open("https://instagram.com/mindfulcare.mx?igshid=YmMyMTA2M2Y=", "_blank")} />
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
}
