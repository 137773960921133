import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { isDesktop } from 'react-device-detect';
import { Grid, Container } from "@material-ui/core";
import AliceCarousel from "react-alice-carousel";
import { getCollections, top } from '../../resources/js/functions';
import Header from "../../components/Header";
import ValidateUser from "../../components/ValidateUser";
import Footer from "../../components/Footer";
import pilarMental from '../../resources/images/home/pilar-emocional.svg';
import pilarFisico from '../../resources/images/home/pilar-fisico.svg';
import pilarNutricion from '../../resources/images/home/pilar-nutricion.svg';
import atras from '../../resources/images/home/atras.svg';
import siguiente from '../../resources/images/home/siguiente.svg';
import "react-alice-carousel/lib/alice-carousel.css";
import "./styles.scss";

export default function Home() {
  const [podcast, setPodcast] = useState([])
  const navigate = useNavigate();
  useEffect(() => {
    top();
    getPodcast();
  }, [])

  const getPodcast = async () => {
    const data = await getCollections('podcast');
    setPodcast(data);
  }

  const renderNextButton = ({ isDisabled }) => {
    return <img src={siguiente} alt="img" style={{ position: "absolute", right: '-6%', top: '30%', cursor: 'pointer', color: "#E9010B" }} />
  };

  const renderPrevButton = ({ isDisabled }) => {
    return <img src={atras} alt="img" style={{ position: "absolute", left: '-6%', top: '30%', cursor: 'pointer', color: "#E9010B" }} />
  };

  return (
    <>
      <ValidateUser>
        <Header />
        <div id="home">
          <div className="section">
            <Container maxWidth="lg" className=" wow fadeIn" data-wow-delay='.2s' data-wow-offset='0' data-wow-duration='.5s'>
              <Grid container direction="row" alignItems="center" justifyContent="center" spacing={5}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <div class="contenedor-streaming"><iframe title="streaming" src="https://player.vimeo.com/video/868783638?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" allow="autoplay; fullscreen" allowfullscreen="" frameborder="0" class="estilo-streaming"></iframe></div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <h2>¡Bienvenidos!</h2>
                  <p>Este es el inicio de una nueva era en la que lo más importante eres TÚ.</p>
                  <p>Queremos ser tu mejor aliado y compañero para que logres bienestar en tu trabajo y en tu vida.</p>
                  <p>Cada práctica, herramienta, frase o consejo que encuentres en este espacio es una invitación para que puedas comenzar un viaje individual hacia TU MEJOR VERSIÓN.</p>
                  <p>Explora, interactúa y usa este espacio las veces que quieras y en cualquier lugar.</p>
                </Grid>
              </Grid>
            </Container>
          </div>
          <Container maxWidth="lg" className=" wow fadeIn mb30" data-wow-delay='.2s' data-wow-offset='0' data-wow-duration='.5s'>
            <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <h1>Nuestros pilares</h1>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <img src={pilarMental} alt="pilar" className="full cursor" onClick={() => navigate('/pilar/emocional-mental')} />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <img src={pilarFisico} alt="pilar" className="full cursor" onClick={() => navigate('/pilar/fisico')} />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <img src={pilarNutricion} alt="pilar" className="full cursor" onClick={() => navigate('/pilar/nutricion')} />
              </Grid>
            </Grid>
          </Container>
          <Container maxWidth="lg" className=" wow fadeIn mb30" data-wow-delay='.2s' data-wow-offset='0' data-wow-duration='.5s'>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <h1>Escucha nuestro podcast </h1>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <AliceCarousel
                  mouseTrackingEnabled
                  items={podcast}
                  autoPlayInterval="2000"
                  autoPlay={true}
                  infinite={true}
                  mouseTracking
                  // startIndex={0}
                  // slideToIndex={0}
                  disableButtonsControls={isDesktop ? false : true}
                  renderPrevButton={renderPrevButton}
                  renderNextButton={renderNextButton}
                  responsive={{
                    425: {
                      items: 2,
                    },
                    640: {
                      items: 1,
                    },
                    1024: {
                      items: 4,
                    },
                  }}
                >
                  {podcast.map((val, index) => {

                    return (
                      <div className="center">
                        <img src={val.portada} alt="portada" className="cursor" style={{ width: '90%' }} onClick={() => navigate(`/podcast/${val.id}`)} />
                      </div>

                    );
                  })}
                </AliceCarousel>
              </Grid>
            </Grid>
          </Container>
        </div>
        <Footer />
      </ValidateUser>
    </>
  );
}
