import React, { useState } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { isDesktop } from 'react-device-detect';
import atras from '../../../resources/images/home/atras.svg';
import siguiente from '../../../resources/images/home/siguiente.svg';
import logo6 from "../../../resources/images/speakers/cliente1.svg";
import logo7 from "../../../resources/images/speakers/cliente2.svg";
import logo8 from "../../../resources/images/speakers/cliente3.svg";
import logo9 from "../../../resources/images/speakers/cliente4.svg";
import logo10 from "../../../resources/images/speakers/cliente5.svg";
import logo11 from "../../../resources/images/speakers/cliente6.svg";
import logo12 from "../../../resources/images/speakers/cliente7.svg";
import logo13 from "../../../resources/images/speakers/cliente8.svg";
import logo14 from "../../../resources/images/speakers/cliente9.svg";
import logo15 from "../../../resources/images/speakers/cliente10.svg";
import logo16 from "../../../resources/images/speakers/cliente11.svg";
import logo17 from "../../../resources/images/speakers/cliente12.svg";
import logo18 from "../../../resources/images/speakers/cliente13.svg";
import logo19 from "../../../resources/images/speakers/cliente14.svg";
import logo20 from "../../../resources/images/speakers/cliente15.svg";
import logo21 from "../../../resources/images/speakers/cliente16.svg";
import logo22 from "../../../resources/images/speakers/cliente17.svg";
import logo23 from "../../../resources/images/speakers/cliente18.svg";

export default function Carousel() {
    const [clientes] = useState([logo6, logo7, logo8, logo9, logo10, logo11, logo12, logo13, logo14, logo15, logo16, logo17, logo18, logo19, logo20, logo21, logo22, logo23]);

    const renderNextButton2 = ({ isDisabled }) => {
        return <img src={siguiente} alt="img" style={{ position: "absolute", right: '-6%', top: '30%', cursor: 'pointer', color: "#E9010B" }} />
    };

    const renderPrevButton2 = ({ isDisabled }) => {
        return <img src={atras} alt="img" style={{ position: "absolute", left: '-6%', top: '30%', cursor: 'pointer', color: "#E9010B" }} />
    };


    return <AliceCarousel
        mouseTrackingEnabled
        items={clientes}
        autoPlayInterval="2000"
        autoPlay={true}
        infinite={true}
        mouseTracking
        // startIndex={0}
        // slideToIndex={0}
        disableButtonsControls={isDesktop ? false : true}
        renderPrevButton={renderPrevButton2}
        renderNextButton={renderNextButton2}
        responsive={{
            425: {
                items: 2,
            },
            640: {
                items: 2,
            },
            1024: {
                items: 3,
            },
        }}
    >
        {clientes.map((val, index) => {

            return (
                <div className="center">
                    <div style={{ backgroundImage: `url(${val})`, filter: "grayscale(1)" }} className="logos" />

                </div>

            );
        })}
    </AliceCarousel>
}